import React, { useEffect } from "react";
import 'bulma/css/bulma.min.css';
import Header from "../pages/header";
import Footer from "../pages/footer";

const Layout = ({ children }) => {
    useEffect(() => {

        // // Create a <noscript> element
        // const noscriptElement = document.createElement('noscript');

        // // Set the inner HTML of the <noscript> element
        // noscriptElement.innerHTML = `
        // <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TPM8DRG"
        //         height="0" width="0" style="display:none;visibility:hidden"></iframe>
        // `;

        // // Insert the <noscript> element as the first child of the body
        // if (document.body.firstChild) {
        //     document.body.insertBefore(noscriptElement, document.body.firstChild);
        // } else {
        //     // If there are no other elements in the body
        //     document.body.appendChild(noscriptElement);
        // }

        // Function to get the value of a query parameter by name
        const getQueryParam = (param) => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
        };

        const gclid = getQueryParam('gclid') || null;

        // Push gclid to dataLayer once during the initial mount
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'mctb_gclid': gclid
        });

        const startFreeTrialWithCreditCardElement = document.createElement('script');
        startFreeTrialWithCreditCardElement.type = 'text/javascript';
        startFreeTrialWithCreditCardElement.innerHTML = `function startFreeTrialWithCreditCardEvent(email, transactionId) {
            console.log('Pushing startFreeTrialWithCreditCardEvent to dataLayer')
            console.log('email: ' + email);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'start_free_trial_with_credit_card',
                'start_free_trial_with_credit_card_details': {
                    'email': email,
                    'transaction_id': transactionId
                }
            })
        }`;
        document.body.appendChild(startFreeTrialWithCreditCardElement);

        const script = document.createElement('script');
        script.src = 'https://cdn.paddle.com/paddle/paddle.js';
        document.body.appendChild(script);

        script.onload = () => {
            const paddleScript = document.createElement('script');
            paddleScript.id = 'paddle-setup';
            paddleScript.type = 'text/javascript';

            if (process.env.GATSBY_CONTEXT === 'dev') {
                // paddleScript.innerHTML = 'Paddle.Setup({ vendor: ' + process.env.GATSBY_DEV_PADDLE_VENDOR_ID + ', eventCallback: function(data) { if (data.event === "Checkout.Complete") { console.log(data.eventData); gtag_report_conversion(undefined, data.eventData.checkout.id) } } });';

                // Test all checkout events
                paddleScript.innerHTML = `
                Paddle.Setup({ 
                    vendor: ` + process.env.GATSBY_DEV_PADDLE_VENDOR_ID + `, 
                    eventCallback: function(data) { 
                        console.log(data); 
                        if (data.event === "Checkout.Complete") { 
                            startFreeTrialWithCreditCardEvent(data.eventData.user.email, data.eventData.checkout.id) 
                        }
                    }
                });`;
                paddleScript.innerHTML += 'Paddle.Environment.set(\'sandbox\');'
            }
            else {
                paddleScript.innerHTML = `
                Paddle.Setup({
                    vendor: ` + process.env.GATSBY_PADDLE_VENDOR_ID + `, 
                    eventCallback: function(data) { 
                        console.log(data); 
                        if (data.event === "Checkout.Complete") { 
                            startFreeTrialWithCreditCardEvent(data.eventData.user.email, data.eventData.checkout.id) 
                        }
                    }
                });`;
            }
            document.body.appendChild(paddleScript);
        }
    }, []);

    return (
        <div>
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
